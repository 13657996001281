// About.js
import React from 'react';
import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import Helmet from "react-helmet";
import {StaticImage} from "gatsby-plugin-image";

const Menu = () => {
    return (
        <>
            <Helmet title="The Traditional Okinawan Goju Ryu Karate-do Association (TOGKA)"/>
            <Container className="App p-0" fluid>
                <Container className="App-header header-colour" fluid>
                    <a href="/">
                        <StaticImage src="../topLogo.png" className="App-logo" alt="logo"/>
                    </a>
                </Container>

            </Container>
            <div className="Menu bg-light p-0">
                <div className="MenuInner p-0">
                    <Navbar bg="light" expand="lg">
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="">
                                <Nav.Item>
                                    <Nav.Link eventKey="1" href="/">Home</Nav.Link>
                                </Nav.Item>
                                <NavDropdown title="About" id="basic-nav-dropdown" link="/About">
                                    <NavDropdown.Item eventKey="4.1" href="/about/History">History of the
                                        TOGKA</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="4.2" href="/about/WhatIsGojuRyu">What is Goju
                                        Ryu?</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="4.3" href="/about/DojoKun">Dojokun</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="4.4" href="/about/TheTOGKAKenkon">The TOGKA
                                        Kenkon</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="4.5" href="/about/DanshaGradings">Dansha
                                        Gradings</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="4.6" href="/about/Kata">Kata</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item eventKey="4.7" href="/about/SenseiRavey">Sensei
                                        Ravey</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Item>
                                    <Nav.Link eventKey="1" href="/Gallery">Gallery</Nav.Link>
                                </Nav.Item>
                                <NavDropdown title="Library" id="nav-dropdown">
                                    <NavDropdown title="Articles" id="nav-dropdown">
                                        <NavDropdown.Item eventKey="4.1"
                                                          href="/library/articles/Spiritualism">Spiritualism</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.2" href="/library/articles/Sanchin">Sanchin
                                            (Life's breath)</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.3" href="/library/articles/TheUnconquerableFoe">The
                                            Unconquerable Foe</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.4"
                                                          href="/library/articles/TheCleansingProgram-Part-1-Oxygen">The
                                            Cleansing
                                            Program - Part 1 Oxygen</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.5" href="/library/articles/TheVoid">The
                                            Void</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.6" href="/library/KarateDoTools">Karate-do/Tools
                                            (My way of life/Arts)</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.7" href="/library/WhatIsAMaster">What is
                                            a master?</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.8"
                                                          href="/library/SevenPrinciplesoftheUniverse">7
                                            Principles of the Universe</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.9" href="/library/articles/SenseiKenOgawa">Sensei
                                            Ken Ogawa</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.10"
                                                          href="/library/RyukyuKenpoKarateDoGaiyo">Ryukyu kenpo
                                            karate-do gaiyo</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.11" href="/library/articles/SundayBloodySundays">Sunday
                                            Bloody Sundays</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.12"
                                                          href="/library/PastMessagesFromSenseiRavey">Past
                                            messages from Sensei Ravey</NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown title="Media Clippings" id="nav-dropdown">
                                        <NavDropdown.Item eventKey="4.2" href="/library/BodyAndSoul">Body and
                                            Soul</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.3" href="/library/DerbyshireTimes">Derbyshire
                                            Times</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.5" href="/library/articles/SenseiGrahamRavey">Sensei
                                            Graham Ravey</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.6"
                                                          href="/library/articles/ArticleSpiritualism">Spiritualiam</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.7" href="/library/OsuGiAdvert">Osu-Gi
                                            Advertisement</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.7"
                                                          href="/library/KarateInstructorsNotHighlyRegarded">Karate
                                            Instructors not highly regarded</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.8"
                                                          href="/library/articles/SpiritedTraditionalOkinawanGojuMeet">Spirited
                                            Traditional Okinawan Goju meet</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.9" href="/library/FirstKarateTournament">First
                                            Karate Tournament</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.9" href="/library/APartingOfTheWays">A
                                            Parting of the Ways</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.9"
                                                          href="/library/OkinawanGojuRyusManWithAMission">Sensei
                                            Graham Ravey, Okinawan Goju Ryu's Man with a Mission</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.9"
                                                          href="/library/articles/VariousGojuRyuNewspaperClippings">1987,
                                            1988 - Various Goju Ryu newspaper clippings</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.9" href="/library/articles/TheWayOfTheEmptyHand">The
                                            Way of the Empty Hand</NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown.Item eventKey="4.1" href="/library/BasicTerminology">Basic
                                        Terminology</NavDropdown.Item>
                                    <NavDropdown title="Videos" id="nav-dropdown">
                                        <NavDropdown.Item eventKey="4.2" href="/library/articles/Videos#tech">Technical
                                            Tips</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.3" href="/library/articles/Videos#misc">Miscellaneous
                                            and fun videos</NavDropdown.Item>
                                    </NavDropdown>
                                </NavDropdown>
                                <Nav.Item>
                                    <Nav.Link eventKey="1" href="/JoinTogka">Join TOGKA</Nav.Link>
                                </Nav.Item>
                                <NavDropdown title="Locations" id="nav-dropdown">
                                    <NavDropdown title="Australia" id="nav-dropdown">
                                        <NavDropdown.Item eventKey="4.2"
                                                          href="/locations/Bassendean">Bassendean</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.2"
                                                          href="/locations/Burleigh">Burleigh</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.3"
                                                          href="/locations/Caboolture">Caboolture</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.1"
                                                          href="/locations/Kilcoy">Kilcoy</NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown title="Tanzania" id="nav-dropdown">
                                        <NavDropdown.Item eventKey="4.7"
                                                          href="/locations/DaresSalaam">Dar es Salaam</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.7"
                                                          href="/locations/Bagamoyo">Bagamoyo</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.7"
                                                          href="/locations/Morogoro">Morogoro</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="4.7"
                                                          href="/locations/Moshi">Moshi</NavDropdown.Item>
                                    </NavDropdown>
                                </NavDropdown>
                                <Nav.Item>
                                    <Nav.Link eventKey="1" href="/Seminars">Seminars</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="1" href="/Contact">Contact Us</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="1" href="/Shop">Shop</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>

                </div>
            </div>
        </>);
}

export default Menu;
